import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'industries', loadChildren: './industries/industries.module#IndustriesModule' },
  { path: 'products', loadChildren: './products/products.module#ProductsModule' },
  { path: 'resources', loadChildren: './resources/resources.module#ResourcesModule' },
  { path: 'healthcare', loadChildren: './industries/healthcare-it/healthcare.module#HealthcareModule' },
  { path: 'travel', loadChildren: './industries/travel/travel.module#TravelModule' },
  { path: 'financial-services', loadChildren: './industries/financial-services/financial.module#FinancialModule' },
  { path: 'automobile', loadChildren: './industries/automobile/automobile.module#AutomobileModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactModule'},
  { path: 'getaquote', loadChildren: './get-a-quote/get-a-quote.module#GetAQuoteModule' },
  { path: 'aboutus', loadChildren: './aboutus/aboutus.module#AboutusModule' }
  ];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
